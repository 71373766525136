import { clamp, isEqual, toInteger } from "lodash";
import { useMemo } from "react";
import { create } from "zustand";

import { noawait } from "@smartrr/shared/utils/noawait";

type LoadReason = "page" | "search" | "dynamic";

interface PreviousSearch<DynamicSearchData> {
  page: number;
  search: string;
  dynamic: DynamicSearchData;
}

interface SearchFormData<DynamicSearchData, SearchResultType> {
  hasLoadedBefore: boolean;
  isLoading: LoadReason | null;
  data: SearchResultType[];
  count: number;
  page: number;
  search: string;
  dynamic: DynamicSearchData;

  previousSearch: PreviousSearch<DynamicSearchData> | null;

  actions: {
    reset(): void;
    refetch(settings: PreviousSearch<DynamicSearchData>): void;
    search: {
      update(newSearch: string): void;
    };
    dynamic: {
      update(newDyanmic: DynamicSearchData): void;
    };
    page: {
      update(newPage: number): void;
    };
  };

  internal: {
    getMoreData(reason: LoadReason): Promise<void>;
    whileLoading(reason: LoadReason, fn: () => Promise<void>): Promise<void>;
    setData(newData: SearchResultType[]): SearchResultType[];
  };
}

type SearchFormStoreFactoryConfig<Type> =
  | {
      /**
       * Add a definition for getId if you use "infinite"
       */
      scrollType: "pagination";
    }
  | {
      scrollType: "infinite";
      getId: (item: Type) => string;
    };

export function SearchFormStoreFactory<DynamicSearchData extends { perPage: number }, SearchResultType>(
  initialDynamic: DynamicSearchData,
  getDataFunc: (
    dynamic: DynamicSearchData,
    page: number,
    search: string
  ) => Promise<{ data: SearchResultType[]; count: number }>,
  config: SearchFormStoreFactoryConfig<SearchResultType>
) {
  const initial = {
    hasLoadedBefore: false,
    isLoading: null,
    data: [],
    count: 0,
    page: 1,
    search: "",
    dynamic: initialDynamic,
    previousSearch: null,
  };

  const useStore = create<SearchFormData<DynamicSearchData, SearchResultType>>()((set, get) => ({
    ...initial,
    actions: {
      reset() {
        set({
          ...initial,
        });
      },
      refetch(settings: PreviousSearch<DynamicSearchData>): void {
        set({
          page: settings.page,
          search: settings.search,
          dynamic: settings.dynamic,
        });
        noawait(async () => get().internal.getMoreData("dynamic"));
      },
      search: {
        update(newSearch: string): void {
          set({ search: newSearch });
          noawait(async () => get().internal.getMoreData("search"));
        },
      },
      dynamic: {
        update(newDyanmic: DynamicSearchData): void {
          set({
            dynamic: newDyanmic,
          });
          noawait(async () => get().internal.getMoreData("dynamic"));
        },
      },
      page: {
        update(newPage: number): void {
          if (get().dynamic.perPage < 1) {
            return;
          }

          const max = get().count > 0 ? get().count / get().dynamic.perPage + 1 : 1;

          if (newPage === clamp(newPage, 1, max)) {
            set({
              page: newPage,
            });
            noawait(async () => get().internal.getMoreData("page"));
          }
        },
      },
    },
    internal: {
      async getMoreData(reason: LoadReason) {
        const newSearch: SearchFormData<DynamicSearchData, SearchResultType>["previousSearch"] = {
          dynamic: get().dynamic,
          page: get().page,
          search: get().search,
        };
        if (isEqual(get().previousSearch, newSearch)) {
          set({ previousSearch: newSearch });
          return;
        }
        set({ previousSearch: newSearch });

        const search = get().search;
        await get().internal.whileLoading(reason, async () => {
          if (reason === "search" || reason === "dynamic") {
            set({
              page: 1,
              data: [],
            });
          }
          if (config.scrollType === "pagination") {
            set({
              data: [],
            });
          }

          const result = await getDataFunc(get().dynamic, get().page, get().search);

          set({
            data: config.scrollType === "pagination" ? result.data : get().internal.setData(result.data),
            count: result.count,
          });
        });
        if (get().search !== search) {
          noawait(() => get().internal.getMoreData("search"));
        }
      },
      setData(newData: SearchResultType[]): SearchResultType[] {
        if (config.scrollType === "pagination") {
          return [...get().data, ...newData];
        }
        const map: Record<string, boolean> = {};
        for (const datum of get().data) {
          map[config.getId(datum)] = true;
        }

        const result = [...get().data];
        for (const newDatum of newData) {
          if (!map[config.getId(newDatum)]) {
            result.push(newDatum);
          }
        }

        return result;
      },
      async whileLoading(reason: LoadReason, fn: () => Promise<void>) {
        if (get().isLoading) {
          return;
        }
        set({
          isLoading: reason,
        });
        await fn();
        set({ isLoading: null });
        if (!get().hasLoadedBefore) {
          set({
            hasLoadedBefore: true,
          });
        }
      },
    },
  }));

  const initialState = useStore.getState();

  return {
    access: {
      usePage() {
        return useStore(state => state.page);
      },
      useLoading() {
        return useStore(state => state.isLoading);
      },
      useHasLoadedBefore() {
        return useStore(state => state.hasLoadedBefore);
      },
      useData() {
        return useStore(state => state.data);
      },
      useActions() {
        return useStore(state => state.actions);
      },
      useSearchTerm() {
        return useStore(state => state.search);
      },
      usePreviousSearch() {
        return useStore(state => state.previousSearch);
      },

      useMaxPage(): number {
        const count = useStore(state => state.count);
        const perPage = useStore(state => state.dynamic.perPage);

        const max = useMemo(() => {
          return count > 0 ? count / perPage + 1 : 1;
        }, [count, perPage]);

        return toInteger(max);
      },

      useHasMorePages(): boolean {
        const max = this.useMaxPage();
        const page = useStore(state => state.page);

        return page < max;
      },
    },
    testing: {
      actions() {
        return useStore.getState().actions;
      },
      reset() {
        useStore.setState(initialState);
      },
      state() {
        return useStore.getState;
      },
    },
  };
}
